import { AuthLoginActionTypes, AuthLoginState } from './authTypes';
import { REHYDRATE } from 'redux-persist';
import { ContactsActionTypes } from '../contacts/contactTypes';

export const INIT_STATE: AuthLoginState = {
  user: null,
  error: '',
  loading: false,
  isUserLogin: false,
  isUserLogout: true,
  isCaptchaVerified: false,
  isGmailPermissionProvided: false,
  captchaRetries: 0,
  userName: '',
  images: '',
  isAdmin: false,
  isAdminOTPVerified: false,
  trackableContactsAssigned: false,
  emailContactsFetched: false,
};


const Login = (state = INIT_STATE, action: any): AuthLoginState => {
  console.log(`Auth Reducer received action type:{My Login} ${action.type}`, action);
  switch (action.type) {
    // case REHYDRATE:
    //   // Merge the persisted state with the initial state
    //   return {
    //       ...state,
    //       ...action.payload?.Auth,
    //   };
    case AuthLoginActionTypes.API_RESPONSE_SUCCESS:
      console.log(`Auth Reducer received action type:{My inside AuthLoginActionTypes} ${action.type}`, action);
      console.log("action.payload.actionType", action.payload.actionType);
      console.log("AuthLoginActionTypes.UPDATE_CONTACT_TRACK_ABILITY", AuthLoginActionTypes.UPDATE_CONTACT_TRACK_ABILITY);


      switch (action.payload.actionType) {
        case AuthLoginActionTypes.LOGIN_USER:
          // console.log("EmailContactFetched for checked",  action.payload.data.user);
          return {
            ...state,
            user: action.payload.data.user,
            loading: false,
            isUserLogin: true,
            isUserLogout: false,
            emailContactsFetched: action.payload.data.user.emailContactsFetched,
          };
        case AuthLoginActionTypes.UPDATE_CONTACT_TRACK_ABILITY:
          console.log(`UPDATE_CONTACT_TRACK_ABILITY triggered:{My Login} ${action.type}`, action);
          return {
            ...state,
            // user: action.payload.data.user,
            // loading: false,
            // isUserLogin: true,
            // isUserLogout: false,
            trackableContactsAssigned: true,
          };
        case AuthLoginActionTypes.GOOGLE_VERIFY:
          console.log("google verification:", action.payload.data.user.full_name);
          return {
            ...state,
            userName: action.payload.data.user.full_name,
            loading: false,
            isUserLogin: true,
            isUserLogout: false,
            emailContactsFetched: action.payload.data.user.emailContactsFetched,
            trackableContactsAssigned: action.payload.data.user.trackableContactsAssigned,
          };
        case AuthLoginActionTypes.GMAIL_VERIFY:
          console.log("inside success gmail verify reducer ", action.payload.data?.user?.full_name);
          console.log("inside success images", action.payload.data?.user?.images);
          return {
            ...state,
            isGmailPermissionProvided: Boolean(action.payload.data.isPermitted),
            isUserLogin: true,
            isUserLogout: false,
            loading: false,
            userName: action.payload.data?.user?.full_name || '',
            images: action.payload.data?.user?.images,
            user: action.payload.data?.user,
            isAdmin: action.payload.data?.user?.isAdmin || false,
            emailContactsFetched: action.payload.data?.user?.emailContactsFetched,
            trackableContactsAssigned: action.payload.data?.user?.trackableContactsAssigned,
          };
        case AuthLoginActionTypes.CAPTCHA_VERIFY:
          return {
            ...state,
            isCaptchaVerified: true,
            captchaRetries: 0,
          };
        case AuthLoginActionTypes.ADMIN_OTP_VERIFY:
          console.log("post api reducer ADMIN_OTP_VERIFY:", action.payload);
          return {
            ...state,
            isAdmin: true,
            isAdminOTPVerified: true,
          };
        case AuthLoginActionTypes.SEND_ADMIN_OTP:
          console.log("Pre api reducer ADMIN_OTP_VERIFY:", action.payload);
          return {
            ...state,
            isAdmin: true,
            isAdminOTPVerified: false,
          };
        case AuthLoginActionTypes.LOGOUT_USER:
          console.log("Logout message received : ", action.payload)
          return {
            ...state,
            loading: false,
            isUserLogin: false,
            isUserLogout: true,
            user: null,
            userName: '',
            isAdmin: false
          };
        default:
          return { ...state };
      }

    case AuthLoginActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AuthLoginActionTypes.LOGIN_USER:
          return {
            ...state,
            error: action.payload.error,
            isUserLogin: false,
            loading: false,
            isAdmin: false,
          };
        case AuthLoginActionTypes.GOOGLE_VERIFY:
          return {
            ...state,
            error: action.payload.error,
            isUserLogin: false,
            loading: false,
          };
        case AuthLoginActionTypes.GMAIL_VERIFY:
          return {
            ...state,
            error: action.payload.error,
            isGmailPermissionProvided: false,
            isUserLogin: false,
            isUserLogout: true,
            user: null,
            loading: false,
            isAdmin: false,
          };
        case AuthLoginActionTypes.CAPTCHA_VERIFY:
          return {
            ...state,
            isCaptchaVerified: false,
            captchaRetries: state.captchaRetries + 1,
          };
        case AuthLoginActionTypes.ADMIN_OTP_VERIFY:
          console.log("Error: post api reducer ADMIN_OTP_VERIFY:", action.payload);
          return {
            ...state,
            isAdmin: false,
            isAdminOTPVerified: false,
          };
        case AuthLoginActionTypes.SEND_ADMIN_OTP:
          console.log("Pre api reducer ADMIN_OTP_VERIFY:", action.payload);
          return {
            ...state,
            isAdmin: false,
            isAdminOTPVerified: false,
          };
        case AuthLoginActionTypes.LOGOUT_USER:
          console.log("Logout message received : ", action.payload)
          return {
            ...state,
            loading: false
          };
        default:
          return { ...state };
      }

    case AuthLoginActionTypes.LOGIN_USER:
      return {
        ...state,
        loading: true,
        isUserLogin: false,
      };

    case AuthLoginActionTypes.UPDATE_CONTACTS_FROM_SSE:
      {
        console.log("Update contacts from SSE inside auth reducer", action.payload);
        console.log("Before Update - emailContactsFetched:", state.emailContactsFetched);
        console.log("Setting emailContactsFetched to false due to SSE contact event");
        const newState = {
          ...state,
          emailContactsFetched: true,
        };
        console.log("After Update - emailContactsFetched:", newState.emailContactsFetched);
        return newState;
      }

    // case AuthLoginActionTypes.UPDATE_CONTACT_TRACK_ABILITY:
    // {
    //   console.log("Update contacts from SSE inside auth reducer", action.payload);
    //   console.log("Before Update - trackableContactsAssigned:", state.trackableContactsAssigned);
    //   console.log("Setting trackableContactsAssigned to false due to SSE contact event");
    //   const newState = {
    //     ...state,
    //     trackableContactsAssigned: true,
    //   };
    //   console.log("After Update - trackableContactsAssigned:", newState.trackableContactsAssigned);
    //   return newState;
    // }

    case AuthLoginActionTypes.CAPTCHA_VERIFY:
      return {
        ...state,
        isCaptchaVerified: false,
        captchaRetries: 0,
      };
    case AuthLoginActionTypes.GMAIL_VERIFY:
      return {
        ...state,
        isGmailPermissionProvided: false,
        captchaRetries: 0,
        loading: true
      };
    case AuthLoginActionTypes.ADMIN_OTP_VERIFY:
      console.log("Pre api reducer ADMIN_OTP_VERIFY:", action.payload);
      return {
        ...state,
        isAdmin: false,
        isAdminOTPVerified: false,
      };
    case AuthLoginActionTypes.SEND_ADMIN_OTP:
      console.log("Pre api reducer ADMIN_OTP_VERIFY:", action.payload);
      return {
        ...state,
        isAdmin: false,
        isAdminOTPVerified: false,
      };
    case AuthLoginActionTypes.RESET_ADMIN_OTP_VERIFICATION:
      return {
        ...state,
        isAdminOTPVerified: false,
      };
    case AuthLoginActionTypes.LOGOUT_USER:
      return {
        ...state,
        loading: false,
        isUserLogout: false,
      };

    default:
      return { ...state };
  }
};

export default Login;
