import { MaiActionsState } from './maiActionTypes';
import { addNewActions, updateActions, generateNotificationMessage, updateActionById } from './utils';
import actions from '../../pages/ActionsTable/actionsWithKey.json'




export const INIT_STATE: MaiActionsState = {
  actionsHistoryId: 0,
  allActions: {}, //  {actions},
  // tags: {},
  loading: false,
  error: '',
  notificationMessage: '',
  sseEstablished: false,
  conversationProcessingProgress: '0/3',
  emailContactsFetched: true,
  emailsExtracted: 0,
  totalEmailsToBeExtracted: 0,
};

const maiActionsReducer = (state = INIT_STATE, action: any): MaiActionsState => {
  console.log(`Auth Reducer received in maiActionsReducer type:{My Actions} ${action.type}`, action);
  // console.log("AuthLoginActionTypes.UPDATE_CONTACT_TRACK_ABILITY",);
  
  switch (action.type) {
    // case 'ADD_NEW_ACTIONS':
    //   return addNewActions(state, action.payload);
    case 'API_RESPONSE_SUCCESS':
      console.log("action reducer error response")
      // console.log("API_RESPONSE_SUCCESS:", action.payload.data);
      switch (action.payload.actionType) {
        case 'ADD_NEW_ACTIONS':
          console.log("add new actions payload", action.payload.data)
          return {
            ...addNewActions(state, action.payload.data),
            actionsHistoryId: action.payload.data.actionsHistoryId,
            loading: false,
            error: '',
          };
        case 'EDIT_ACTIONS':
          console.log("add new actions payload", action.payload.data)
          const newAllActions = {...state.allActions}
          newAllActions[action.payload.data.action_id] = action.payload.data
          return{
            ...state,
            allActions: newAllActions
          }
        case 'UPDATE_ACTIONS':
          return {
            ...updateActions(state, action.payload.data),
            actionsHistoryId: action.payload.data.actionsHistoryId,
            loading: false,
            error: '',
          };
        default:
          return { ...state };
      }

    case 'API_RESPONSE_ERROR':
      console.log("action reducer error response")
      switch (action.payload.actionType) {
        case 'ADD_NEW_ACTIONS':
        case 'UPDATE_ACTIONS':
          return {
            ...state,
            loading: false,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    case 'FETCH_NEW_ACTIONS_REQUEST':
      // console.log("Fetch new actions pre api triggerred")
      return {
        ...state,
        loading: true,
        error: '',
      };

    case 'RESET_SSE':
      return {
        ...state,
        sseEstablished: false,
      };
    
    case 'GET_REALTIME_SSE':
      console.log("inside pre api reducer conversationProcessingProgress is set to ", 'extracting conversations' )
      return {
        ...state,
        conversationProcessingProgress: 'extracting conversations'
      }

    case 'RESET_NOTIFICATION':
      return {
        ...state,
        notificationMessage: ''
      }

    case 'EDIT_ACTIONS':
      console.log("inside pre api reducer of EDIT ", action.payload )
      return {
        ...state,
      }


      case 'SEND_EMAIL_PROCESSING_SUMMARY_TO_CLIENT_SSE': {
        console.log("Update in SEND_EMAIL_PROCESSING_SUMMARY_TO_CLIENT_SSE from SSE", action.payload);

        // console.log("Before Update - emailContactsFetched:", state.emailContactsFetched);
  
        // When data with type 'contact' is received, update the state
          console.log("Setting emailContactsFetched to false due to SSE contact event");
          console.log("Before Update - emailsExtracted:", state.emailsExtracted, "totalEmailsToBeExtracted:", state.totalEmailsToBeExtracted);

          const newState = {
            ...state,
            emailsExtracted: action.payload.emailsExtracted ?? state.emailsExtracted,
            totalEmailsToBeExtracted: action.payload.totalEmailsToBeExtracted ?? state.totalEmailsToBeExtracted,
            
        };
        // console.log("After Update - emailContactsFetched:", newState.emailContactsFetched);
        console.log("After Update - emailsExtracted:", newState.emailsExtracted, "totalEmailsToBeExtracted:", newState.totalEmailsToBeExtracted);


        return newState;
        
      }
      
      

    case 'UPDATE_ACTIONS_FROM_SSE': {

      console.log(" update actions from sse", action.payload)
      const { New, Updated, actionsHistoryId, progress, message } = action.payload;

        // Log individual values for clarity
      console.log("New Actions:", New);
      console.log("Updated Actions:", Updated);
      console.log("Actions History ID:", actionsHistoryId);
      console.log("Progress:", progress);
      console.log("Message received ", message)

      // Handle new actions: add them to the state
      const newActions = New.actions.reduce((acc: any, action: any) => {
        acc[action.action_id] = action;
        return acc;
      }, {});

      let updatedActions =[]

      // Handle updated actions: update existing actions in the state
      updatedActions = Updated.actions.reduce((acc: any, action: any) => {
        if (state.allActions[action.action_id]) {
          acc[action.action_id] = {
            ...state.allActions[action.action_id], // preserve existing data
            ...action // update with new data
          };
        }
        return acc;
      }, {});


      let notificationMessage = ''

      // Generate the notification message using the utility function
      if( newActions.length !== 0  || updatedActions.length !== 0 ){
        notificationMessage = generateNotificationMessage(New.actions, Updated.actions);
      }

      console.log("Notification message is :", notificationMessage)
      
      console.log("Actions History id being set is >> ", actionsHistoryId)

      return {
        ...state,
        allActions: {
          ...state.allActions,
          ...newActions, // add new actions
          ...updatedActions, // update existing actions
        },
        notificationMessage: notificationMessage !== ''? notificationMessage : state.notificationMessage, // Set the message only if valid
        actionsHistoryId: actionsHistoryId,
        conversationProcessingProgress: progress
      }
    }

    default:
      return state;
  } 
};

export default maiActionsReducer;
