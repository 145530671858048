import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import useRedux from '../hooks/useRedux';
import { createSelector } from "reselect";

// helpers
// import { APICore } from '../helpers/api/apiCore';

// hooks
// import { useUser } from '../hooks';

type PrivateRouteProps = {
    component: React.ComponentType;
    roles?: string;
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
    
    // global store
    const { dispatch, useAppSelector } = useRedux();

    // Selector to get the required data from the Redux store
    const errorData = createSelector(
        (state: any) => state.Auth,
        (state) => ({
            isUserLogin: state.isUserLogin,
            error: state.error,
            loginLoading: state.loading,
            isUserLogout: state.isUserLogout,
            user: state.user,
          })
        );
    // Inside your component
    const { isUserLogin,error ,loginLoading,isUserLogout, user} = useAppSelector(errorData);    
    const navigate = useNavigate();
    const location = useLocation();
    console.log("user inside private route ", user)

    // let location = useLocation();
    // const [loggedInUser] = useUser();

    // const api = new APICore();

    // /**
    //  * not logged in so redirect to login page with the return url
    //  */
    if (!user) {
        return <>{<Navigate to={'/auth/login'}  replace />}</>;
    } else {
        return <RouteComponent />;
    }

    // // check if route is restricted by role
    // if (roles && roles.indexOf(loggedInUser.role) === -1) {
    //     // role not authorised so redirect to home page
    //     return <Navigate to={{ pathname: '/' }} />;
    // }

    
};

export default PrivateRoute;


