import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { AuthLoginActionTypes } from './authTypes';
import {
  authLoginApiResponseSuccess,
  authLoginApiResponseError,
} from './authActions';
import {
  nodeLogin,
  postSocialLogin,
  postGoogleLogin,
  getGoogleVerify,
  postVerifyCaptcha,
  getGmailVerify,
  verifyEmailOTP as verifyEmailOTPApi,
  sendEmailOTP as sendEmailOTPApi,
  logout
} from '../../api/authNode'
import { persistor } from '../store/index';
import { AtSign } from 'react-feather';

import { showSuccessNotification, showErrorNotification } from "../../../src/helpers/NotificationContext"

function* loginUser({ payload: { user } }: any) {
  try {
    const response: Promise<any> = yield call(nodeLogin, {
      email: user.email,
      password: user.password,
    });
    yield put(
      authLoginApiResponseSuccess(AuthLoginActionTypes.LOGIN_USER, response)
    );
  } catch (error: any) {
    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.LOGIN_USER, error)
    );
  }
}

function* socialLogin({ payload: { data, type } }: any) {
  try {
    const response: Promise<any> = yield call(postGoogleLogin, null);
    yield put(
      authLoginApiResponseSuccess(AuthLoginActionTypes.LOGIN_USER, response)
    );
  } catch (error: any) {
    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.LOGIN_USER, error)
    );
  }
}

function* googleVerify() {
  try {
    const response: Promise<any> = yield call(getGoogleVerify);
    yield put(
      authLoginApiResponseSuccess(AuthLoginActionTypes.GOOGLE_VERIFY, response)
    );
  } catch (error: any) {
    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.GOOGLE_VERIFY, error)
    );
  }
}

function* gmailVerify() {
  try {
    const response: Promise<any> = yield call(getGmailVerify);
    yield put(
      authLoginApiResponseSuccess(AuthLoginActionTypes.GMAIL_VERIFY, response)
    );
  } catch (error: any) {
    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.GMAIL_VERIFY, error)
    );
  }
}


function* verifyCaptcha({ payload: { data } }: any) {
  try {
    const response: Promise<any> = yield call(postVerifyCaptcha, { data });
    yield put(
      authLoginApiResponseSuccess(AuthLoginActionTypes.CAPTCHA_VERIFY, response)
    );
  } catch (error: any) {
    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.CAPTCHA_VERIFY, error)
    );
  }
}

// function* updateTrackAbility({ payload: { id } }: any) {
//   try {
//     console.log("track about to call api Trackablity", id )
//     const response: Promise<any> = yield call(updateTrackAbilityApi, id );
//     console.log("track post api call ", response)
//     yield put(
//       authLoginApiResponseError(AuthLoginActionTypes.UPDATE_CONTACT_TRACK_ABILITY, response)
//     );
//   } catch (error: any) {
//     yield put(
//       authLoginApiResponseError(AuthLoginActionTypes.UPDATE_CONTACT_TRACK_ABILITY, error)
//     );
//   }
// }

function* verifyAdminOTP({ payload: { data } }: any) {
  try {
    const response: Promise<any> = yield call(verifyEmailOTPApi, { data });
    yield put(
      authLoginApiResponseSuccess(AuthLoginActionTypes.ADMIN_OTP_VERIFY, response)
    );
    yield put(authLoginApiResponseSuccess('OTP Verified', response));
  } catch (error: any) {
    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.ADMIN_OTP_VERIFY, error)
    );
  }
}


function* sendEmailOTP({ payload: { email } }: any) {
  try {
    console.log("inside saga function for get AdminOTP")
    const response: Promise<any> = yield call(sendEmailOTPApi, { email });
    yield put(
      authLoginApiResponseSuccess(AuthLoginActionTypes.SEND_ADMIN_OTP, response)
    );
    yield put(authLoginApiResponseSuccess('OTP Sent Successfully', response));
  } catch (error: any) {
    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.SEND_ADMIN_OTP, error)
    );
  }
}


function* logoutUser() {
  try {
    // Call the API to log out
    const response: Promise<any> = yield call(logout);

    // Remove user data from local storage after successful API call
    localStorage.removeItem('authUser');

    // Clear persisted state
    yield call([persistor, 'purge']); // Purge persisted state

    yield put(
      authLoginApiResponseSuccess(AuthLoginActionTypes.LOGOUT_USER, response)
    );
  } catch (error: any) {
    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.LOGOUT_USER, error)
    );
  }
}

export default function* authSaga() {
  yield takeEvery(AuthLoginActionTypes.LOGIN_USER, loginUser);
  yield takeEvery(AuthLoginActionTypes.LOGOUT_USER, logoutUser);
  yield takeLatest(AuthLoginActionTypes.SOCIAL_LOGIN, socialLogin);
  yield takeLatest(AuthLoginActionTypes.GOOGLE_VERIFY, googleVerify);
  yield takeLatest(AuthLoginActionTypes.GMAIL_VERIFY, gmailVerify);
  yield takeLatest(AuthLoginActionTypes.CAPTCHA_VERIFY, verifyCaptcha);
  yield takeLatest(AuthLoginActionTypes.ADMIN_OTP_VERIFY, verifyAdminOTP);
  yield takeLatest(AuthLoginActionTypes.SEND_ADMIN_OTP, sendEmailOTP);
}
