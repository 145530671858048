import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Login from '../auth/authReducer'; // Import your auth reducer
import Layout from '../layout/reducers';
import PageTitle from '../pageTitle/reducers';
import maiActionsReducer from '../maiAction/maiActionReducer'
import contactReducer from '../contacts/contactReducer';
import debugLogsReducer from '../DebugLogs/debugLogsReducer';
// import actionsReducer from '../reducers/actionsReducer'; // Import your actions reducer

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    // whitelist: ['Auth'],
    blacklist: ['Action','Contact', 'DebugLogs', 'Layout']   //['Action','Auth','Contact'], // Don't persist the actions reducer 
};

const rootReducer = combineReducers({
    Auth: Login,
    Layout: Layout,
    PageTitle: PageTitle,
    Action:maiActionsReducer,
    Contact: contactReducer,
    DebugLogs: debugLogsReducer,
    // actions: actionsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
