// contactsReducer.ts

import { boolean } from 'yup';
import { ContactsActionTypes, ContactsState } from './contactTypes';

export const INIT_STATE: ContactsState = {
  contacts: {},
  followUpConfig: {
    "contact_type_rule": [
      {
        "contact_type": "staff",
        "tags": [""],
        "followup_mail_type": "draft"
      },
      {
        "contact_type": "client",
        "tags": [""],
        "followup_mail_type": "sent"
      },
      {
        "contact_type": "peers",
        "tags": [""],
        "followup_mail_type": "sent"
      },
      {
        "contact_type": "self",
        "tags": [""],
        "followup_mail_type": "sent"
      },
      {
        "contact_type": "contacts",
        "tags": [""],
        "followup_mail_type": "sent"
      }
    ],
    "individual_contact_rule": [
      {
        "contact_id": 1,
        "tags": ["day_end"],
        "followup_mail_type": "sent"
      }
    ],
    "tags_to_time_mapping": {
      "day_start_time": "14:20:00",
      "day_end_time": "18:00:00",
      "time_zone": "Asia/Kolkata",
      "week_start_day": "Monday",
      "week_end_day": "Friday"
    },
    rule_json: undefined
  },
  trackable: true,
  loading: false,
  error: '',
};

const contactReducer = (state = INIT_STATE, action: any): ContactsState => {
  // console.log(`Auth Reducer received action type:{My Contact} ${action.type}`, action);
  switch (action.type) {
    // Success handler for API responses
    case ContactsActionTypes.API_RESPONSE_SUCCESS:
      console.log("contract reducer success response")
      // console.log(`Auth Reducer received action type:{My Inside switch case one} ${action.type}`, action);
      switch (action.payload.actionType) {
        case ContactsActionTypes.GET_CONTACTS:
          console.log("get api success reducer triggered ", action.payload.data.contacts)
          return {
            ...state,
            contacts: action.payload.data.contacts,
            loading: false,
            error: '',
          };

        case ContactsActionTypes.UPDATE_CONTACT_TYPE:
          const { contact_id, contact_type } = action.payload.data.data;
          console.log("success api for update_contact_type : ", action.payload.data.data)
          console.log("success api for update_contact_type : ", contact_id, contact_type)
          return {
            ...state,
            contacts: {
              ...state.contacts,
              [contact_id]: {
                ...state.contacts[contact_id],
                contact_type: contact_type,
              },
            },
            loading: false,
            error: '',
          };

        case ContactsActionTypes.UPDATE_CONTACT_TRACK_ABILITY:
          // console.log(`UPDATE_CONTACT_TRACK_ABILITY Post Api:{My Update Post Api} ${action.type}`, action);
          console.log("get api success reducer of UPDATE_CONTACT_TRACK_ABILITY triggered ", action.payload.data.contacts)
          return {
            ...state,
            contacts: action.payload.data.contacts,
            loading: false,
            error: '',
          };

        case ContactsActionTypes.SET_FOLLOWUP_CONFIG:
          console.log("setfollowupconfig post api reducer triggered", action.payload.data.ruleJson)
          return {
            ...state,
            followUpConfig: action.payload.data.ruleJson,
            loading: false,
            error: '',
          };

        case ContactsActionTypes.GET_FOLLOWUP_CONFIG:  // New case for getting follow-up config
          console.log("getfollowupconfig post api reducer triggered", action.payload.data.data.ruleJson)
          return {
            ...state,
            followUpConfig: action.payload.data.data.ruleJson,
            loading: false,
            error: '',
          };

        default:
          return { ...state };
      }

    // Error handler for API responses
    case ContactsActionTypes.API_RESPONSE_ERROR:
      console.log("contract reducer error response")
      return {
        ...state,
        loading: false,
        error: typeof action.payload.error === 'string' ? action.payload.error : "An error occurred in Contact reducer response",
      };

    // Actions to set loading state before API call
    case ContactsActionTypes.GET_CONTACTS:
      console.log(" get contacts is pre api is triggered ")
      return { ...state };
    case ContactsActionTypes.UPDATE_CONTACT_TYPE:
      console.log("update contact type is pre api is triggered ", action.payload);
      return {
        ...state,
        loading: true,
      };
    case ContactsActionTypes.UPDATE_CONTACT_TRACK_ABILITY:
      console.log("update contact Update Track ability is pre api is triggered track", action.payload);
      return { ...state };

    case ContactsActionTypes.SET_FOLLOWUP_CONFIG:
      console.log("contacts pre api reducer triggered")
      return {
        ...state,
        loading: true,
        error: '',
      };
    case ContactsActionTypes.GET_FOLLOWUP_CONFIG:  // Loading state before fetching follow-up config
      return {
        ...state,
        loading: true,
        error: '',
      };
    case ContactsActionTypes.SET_USER_TIME_ZONE:
      console.log("contacts pre api reducer triggered", action.payload);
      console.log("follow up config in pre api reducer triggered", state.followUpConfig);
      const newFollowUpConfig = {
        ...state.followUpConfig,
        tags_to_time_mapping: {
          ...state.followUpConfig.tags_to_time_mapping, // Create a new object for nested properties
          time_zone: action.payload, // Update the time_zone property
        },
      };
      return {
        ...state,
        followUpConfig: newFollowUpConfig,
        loading: true,
        error: '',
      };
    default:
      return { ...state };
  }
};

export default contactReducer;