import { APINodeClient } from "./apiNodeCore";
import { eventChannel, END } from 'redux-saga';
import { updateActionsFromSSE } from "../redux/actions";
import * as nodeUrl from "./nodeUrls";


const api = new APINodeClient();


const editActions = (data: any) => api.create(nodeUrl.EDIT_ACTIONS, data);

const getAllActions = (userId: number, historyId: number) => {
 
    const baseUrl = '/getAllActions'//'/get-actions-from-last-week-mails'              //  '/getAllActions'
    const params = { userId, historyId }; // This will be { userId: 333 } when you pass 333 as the argument
    console.log("getAllActions params ", params);

  
    return api.get(baseUrl, params);
  }




// sseUtils.ts


const createSSEChannel = (
    startListeningToSSE: Function,
    stopListeningToSSE: Function,
    userId: number,
    historyId: number
  ) => {
    return eventChannel(emitter => {
      const eventSource = startListeningToSSE(userId, historyId);
  
      if (!eventSource || !(eventSource instanceof EventSource)) {
        console.error('Failed to create EventSource'); // Log error
        emitter({ type: 'SSE_ERROR', error: 'Failed to create EventSource' });
        emitter(END);
        return () => {}; // Return a no-op function
      }
  
      eventSource.onmessage = (event: MessageEvent) => {
        console.log('Event received:', event); // Log the event
        const data = JSON.parse(event.data);
        console.log('Parsed SSE data:', data); // Log parsed data
        // emitter({ type: 'NEW_SSE_DATA', data });
        if (data.type === 'contact') {
          emitter({ type: 'contact', data });
        } else if (data.type === 'emailProcessing') {
          console.log("data inside emailProcessing", data);

          emitter({ type: 'emailProcessing', data });
        }
        else {
          emitter({ type: 'NEW_SSE_DATA', data });
        }
      };
  

      eventSource.onerror = (error: Event) => {
        console.error('SSE encountered an error:', error); // Log the error
        emitter({ type: 'SSE_ERROR', error });
        if (eventSource.readyState === EventSource.CLOSED) {
            console.log('SSE connection closed'); // Log SSE close
            emitter({ type: 'SSE_CLOSED' });
            emitter(END);
        }
      };
  
      eventSource.addEventListener('close', () => {
        console.log('SSE closed by server'); // Log the close event
        emitter({ type: 'SSE_CLOSED' });
        emitter(END); // Close the channel
      });
  
      return () => {
        stopListeningToSSE();
      };
    });
  };


export { getAllActions, editActions, createSSEChannel };
  