// actions.ts
import { AddNewActionsPayload, UpdateActionsPayload } from './maiActionTypes';

export const apiResponseSuccess = (actionType: string, data: any) => ({
  type: 'API_RESPONSE_SUCCESS',
  payload: { actionType, data },
});

export const apiResponseError = (actionType: string, error: string) => ({
  type: 'API_RESPONSE_ERROR',
  payload: { actionType, error },
});

export const fetchNewActionsRequest = (payload: { userId: number, actionsHistoryId: number }) => ({
  type: 'FETCH_NEW_ACTIONS_REQUEST',
  payload,
});

export const resetNotification = (payload: any) => ({
  type: 'RESET_NOTIFICATION',
  payload,
});

export const addNewActions = (payload: AddNewActionsPayload) => ({
  type: 'ADD_NEW_ACTIONS',
  payload,
});

export const editActions = (payload: any) => ({
  type: 'EDIT_ACTIONS',
  payload,
});

export const getRealTimeSSE = (payload: { userId: number, historyId: number }) => ({
  type: 'GET_REALTIME_SSE',
  payload,
});

export const updateActionsFromSSE = (payload: any) => ({
  type: 'UPDATE_ACTIONS_FROM_SSE',
  payload
});

// make an action for contact
// export const updateContactsFromSSE = (payload: any) => ({
//   type: 'UPDATE_CONTACTS_FROM_SSE',
//   payload

// });

export const sendEmailProcessingSummaryToClientSSE = (payload: any) => ({
  type: 'SEND_EMAIL_PROCESSING_SUMMARY_TO_CLIENT_SSE',
  payload
});

export const resetSSE = (payload: any) => ({
  type: 'RESET_SSE',
  payload,
});

